.k-input {
  border: 1px solid #d0d0ce;

  &.k-focus {
    border: 1px solid #000;

    &:after {
      border: none;
    }
  }

  &:focus-visible {
    outline: 2px solid #005587;
    outline-offset: -2px;
  }

  &-inner {
    padding: 5px 12px;
  }
}

.k-label {

  &_required {

    .k-label:after {
      content: '*';
      margin-left: 4px;
      color: #DA291C;
    }
  }
}

.k-dateinput {
  align-items: flex-start;

  &.k-input:focus::after,
  &.k-input.k-focus::after,
  &.k-input:focus-within::after {
    content: none;
  }
}

.k-datepicker {
  align-items: flex-start;

  .k-input-button {

    &:before {
      content: "\e90c";
      display: block !important;
      font-family: 'icon';
      font-size: 16px;
      font-weight: 300;
      line-height: 16px;
    }

    .k-button-icon {
      display: none;
    }
  }
}

.k-calendar {
  font-family: 'Open Sans', sans-serif;

  &-header {
    .k-button-flat:hover {
      background-color: #00000014 !important;
      box-shadow: inset 0 0 0 1px #0000;
    }
  }

  &-table {
    min-width: 100% !important;
  }

  &-view {
    margin: 0;
    padding: 9px 0 12px;
  }

  .k-link {
    border-radius: 0 !important;
  }

  &-td:hover .k-link,
  &-td.k-hover .k-link {
    background-color: #00000014;
    box-shadow: inset 0 0 0 1px #0000;
    color: #000;
  }

  &-td.k-selected:focus .k-link,
  &-td.k-selected.k-focus .k-link {
    background-color: #000;
    box-shadow: inset 0 0 0 1px #000;
    color: #fff;
    border: none;
  }

  &-td.k-today {
    .k-link {
      background-color: #0000;
      box-shadow: inset 0 0 0 1px #d0d0ce;
      color: #000;
    }

  }
}
