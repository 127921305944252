.k-radio {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  transition: all .15s linear;

  &:before {
    width: 4px;
    height: 4px;
  }

  &:hover {
    background: #d6d6d6;

    &:before {
      content: none;
    }
  }
  &:checked {
    background-color: #26890d;
    border-color: transparent;;

    &:before {
      background-color: #fff;
    }

    &:disabled {
      background-color: #26890d;
    }

    &:hover {
      background-color: #1a5d09;

      &:before {
        content: '';
        background-color: #fff;
      }
    }
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 2px solid #005587;
    outline-offset: -2px;
  }
}
