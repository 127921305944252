@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Extra Bold'), local('OpenSans-Extra-Bold'), url('fonts/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Extra Bold Italic'), local('OpenSans-Extra-Bold-Italic'),
    url('fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold Italic'), local('OpenSans-Bold-Italic'), url('fonts/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semi Bold'), local('OpenSans-Semi-Bold'), url('fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semi Bold Italic'), local('OpenSans-Semi-Bold-Italic'), url('fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url('fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light'), local('OpenSans-Light'), url('fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Light Italic'), local('OpenSans-Light-Italic'), url('fonts/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
