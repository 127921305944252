.k-list {

  &-ul {
    background-color: #fff;
    padding: 7px 0;
    border: 1px solid #d0d0ce;
  }

  &-item {
    padding: 4px 11px;
    min-height: 32px;
    border: 0;

    &:hover {
      background-color: #ebebeb;
    }
    &.k-selected,
    &.k-selected:hover,
    &.k-focus {
      box-shadow: none;
      background-color: #e0e0e0;
    }
  }
}
