.k-tooltip {
  background: #000;
  font-size: 14px;
  color: #fff;
  padding: 9px 16px 11px;
  border-radius: 0;

  .k-callout {
    color: #000;
  }

  .k-callout-n {
    top: -0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-width: 0.2rem;
  }
}
