.k-picker {
  padding: 5px 8px 7px 12px;
  min-height: 32px;
  background: #fff;
  box-shadow: inset 0 0 0 1px #d0d0ce;
  border: none;
  outline: none;
  border-radius: 0;
  font-family: Open Sans, sans-serif;

  &:hover {
    box-shadow: inset 0 0 0 1px #afafad;
  }

  &.k-focus {
    box-shadow: inset 0 0 0 1px #000;
    border: none;

    &:after {
      content: none;
    }
  }

  &[aria-expanded=true] .k-button:after {
    rotate: 180deg;
  }

  .k-input-inner {
    padding: 0;
  }

  .k-button {
    justify-content: flex-end;
    padding: 0;
    min-width: auto;
    min-height: auto;

    &:after {
      content: "\e932";
      display: block !important;
      font-family: "icon";
      color: #000;
      font-size: 16px;
      font-weight: 300;
      line-height: 16px;
    }

    .k-button-icon {
      display: none;
    }
  }
}


