@font-face {
  font-family: 'icon';
  src:
    url('fonts/icon.ttf') format('truetype'),
    url('fonts/icon.woff?59r3dn') format('woff'),
    url('fonts/icon.svg?59r3dn#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_file_csv__l__filled:before {
  content: "\e939";
}
.icon_file_doc__l__filled:before {
  content: "\e93a";
}
.icon_file_png__l__filled:before {
  content: "\e93b";
}
.icon_file_psd__l__filled:before {
  content: "\e93c";
}
.icon_file_pdf__l__filled:before {
  content: "\e93d";
}
.icon_file_txt__l__filled:before {
  content: "\e93e";
}
.icon_file_xls__l__filled:before {
  content: "\e93f";
}
.icon_file_xml__l__filled:before {
  content: "\e940";
}
.icon_file_zip__l__filled:before {
  content: "\e941";
}
.icon_file_jpg__l__filled:before {
  content: "\e942";
}
.icon_file_other__l__filled:before {
  content: "\e943";
}
.icon_search__l__stroke:before {
  content: "\e96b";
}
.icon_ascending_sorting__l__stroke:before {
  content: "\e96c";
}
.icon_descending_sorting__l__stroke:before {
  content: "\e96d";
}
.icon_sort_down__l__stroke:before {
  content: "\e96e";
}
.icon_sort_up__l__stroke:before {
  content: "\e96f";
}
.icon_upload__l__stroke:before {
  content: "\e970";
}
.icon_download__l__stroke:before {
  content: "\e971";
}
.icon_delete__l__stroke:before {
  content: "\e972";
}
.icon_bold__l__stroke:before {
  content: "\e973";
}
.icon_italic__l__stroke:before {
  content: "\e974";
}
.icon_underline__l__stroke:before {
  content: "\e975";
}
.icon_strikethrough__l__stroke:before {
  content: "\e976";
}
.icon_superscript__l__stroke:before {
  content: "\e977";
}
.icon_subscript__l__stroke:before {
  content: "\e978";
}
.icon_unordered_list__l__stroke:before {
  content: "\e979";
}
.icon_orderlist__l__stroke:before {
  content: "\e97a";
}
.icon_truncate__l__stroke:before {
  content: "\e97b";
}
.icon_context_menu__l__filled:before {
  content: "\e97c";
}
.icon_draggable_indicator__l__stroke:before {
  content: "\e97d";
}
.icon_align_right__l__stroke:before {
  content: "\e97e";
}
.icon_align_center__l__stroke:before {
  content: "\e97f";
}
.icon_align_left__l__stroke:before {
  content: "\e980";
}
.icon_link__l__stroke:before {
  content: "\e981";
}
.icon_bookmark__l__stroke:before {
  content: "\e982";
}
.icon_bookmark__l__filled:before {
  content: "\e9ed";
}
.icon_info__l__stroke:before {
  content: "\e983";
}
.icon_close__l__stroke:before {
  content: "\e984";
}
.icon_plus__l__stroke:before {
  content: "\e985";
}
.icon_minus__l__stroke:before {
  content: "\e986";
}
.icon_include__l__stroke:before {
  content: "\e987";
}
.icon_add_line__l__stroke:before {
  content: "\e988";
}
.icon_add_folder__l__stroke:before {
  content: "\e989";
}
.icon_heading_1__l__stroke:before {
  content: "\e98a";
}
.icon_heading_2__l__stroke:before {
  content: "\e98b";
}
.icon_heading_3__l__stroke:before {
  content: "\e98c";
}
.icon_user__l__stroke:before {
  content: "\e98d";
}
.icon_users__l__stroke:before {
  content: "\e98e";
}
.icon_add_user__l__stroke:before {
  content: "\e98f";
}
.icon_achievement__l__stroke:before {
  content: "\e99a";
}
.icon_calendar__l__stroke:before {
  content: "\e99b";
}
.icon_resize__l__stroke:before {
  content: "\e99c";
}
.icon_chevron_down__l__stroke:before {
  content: "\e99d";
}
.icon_chevron_up__l__stroke:before {
  content: "\e99e";
}
.icon_chevron_left__l__stroke:before {
  content: "\e99f";
}
.icon_chevron_right__l__stroke:before {
  content: "\e9a0";
}
.icon_checkmark__l__stroke:before {
  content: "\e9a1";
}
.icon_select_all__l__stroke:before {
  content: "\e9a2";
}
.icon_adjustments__l__stroke:before {
  content: "\e9a3";
}
.icon_archive__l__stroke:before {
  content: "\e9a4";
}
.icon_edit__l__stroke:before {
  content: "\e9a5";
}
.icon_copy__l__stroke:before {
  content: "\e9a6";
}
.icon_chat__l__stroke:before {
  content: "\e9a7";
}
.icon_file__l__stroke:before {
  content: "\e9a8";
}
.icon_folder__l__stroke:before {
  content: "\e9a9";
}
.icon_email__l__stroke:before {
  content: "\e9aa";
}
.icon_crop__l__stroke:before {
  content: "\e9ab";
}
.icon_setting__l__stroke:before {
  content: "\e9ac";
}
.icon_exit__l__stroke:before {
  content: "\e9ad";
}
.icon_filter__l__stroke:before {
  content: "\e9ae";
}
.icon_home__l__stroke:before {
  content: "\e9af";
}
.icon_show__l__stroke:before {
  content: "\e9b0";
}
.icon_hide__l__stroke:before {
  content: "\e9b1";
}
.icon_attachment__l__stroke:before {
  content: "\e9b2";
}
.icon_clock__l__stroke:before {
  content: "\e9b3";
}
.icon_arrange_horizontally__l__stroke:before {
  content: "\e9b4";
}
.icon_arrange_vertically__l__stroke:before {
  content: "\e9b5";
}
.icon_arrow_down__l__stroke:before {
  content: "\e9b6";
}
.icon_arrow_up__l__stroke:before {
  content: "\e9b7";
}
.icon_arrow_left__l__stroke:before {
  content: "\e9b8";
}
.icon_arrow_right__l__stroke:before {
  content: "\e9b9";
}
.icon_arrow_rotate_right__l__stroke:before {
  content: "\e9ba";
}
.icon_arrow_back__l__stroke:before {
  content: "\e9bb";
}
.icon_arrow_redo__l__stroke:before {
  content: "\ea2b";
}
.icon_arrow_turn_right__l__stroke:before {
  content: "\e9bc";
}
.icon_hourglass__l__stroke:before {
  content: "\e9bd";
}
.icon_canceled_hourglass__l__stroke:before {
  content: "\e9be";
}
.icon_print__l__stroke:before {
  content: "\e9bf";
}
.icon_alert__l__stroke:before {
  content: "\e9c0";
}
.icon_target__l__stroke:before {
  content: "\e9c1";
}
.icon_exclude__l__stroke:before {
  content: "\e9c2";
}
.icon_help__l__stroke:before {
  content: "\e9c3";
}
.icon_cancel__l__stroke:before {
  content: "\e9c4";
}
.icon_lightbulb__l__stroke:before {
  content: "\e9c5";
}
.icon_location__l__stroke:before {
  content: "\e9c6";
}
.icon_notification__l__stroke:before {
  content: "\e9c7";
}
.icon_heart__l__stroke:before {
  content: "\e9c8";
}
.icon_heart__l__filled:before {
  content: "\e9ee";
}
.icon_accrual__l__stroke:before {
  content: "\e9c9";
}
.icon_credit_memo__l__stroke:before {
  content: "\e9ca";
}
.icon_percentage__l__stroke:before {
  content: "\e9cb";
}
.icon_grid__l__stroke:before {
  content: "\e9cc";
}
.icon_pin__l__stroke:before {
  content: "\e9cd";
}
.icon_locked__l__stroke:before {
  content: "\e9ce";
}
.icon_unlocked__l__stroke:before {
  content: "\e9cf";
}
.icon_camera__l__stroke:before {
  content: "\e9d0";
}
.icon_screen__l__stroke:before {
  content: "\e9d1";
}
.icon_play_bordered__l__stroke:before {
  content: "\e9d2";
}
.icon_pause_bordered__l__stroke:before {
  content: "\e9d3";
}
.icon_play__l__stroke:before {
  content: "\e9d4";
}
.icon_pause__l__stroke:before {
  content: "\e9d5";
}
.icon_share__l__stroke:before {
  content: "\e9d6";
}
.icon_external_link__l__stroke:before {
  content: "\e9d7";
}
.icon_star__l__stroke:before {
  content: "\e9d8";
}
.icon_star__l__filled:before {
  content: "\e9d9";
}
.icon_cut__l__stroke:before {
  content: "\e9da";
}
.icon_align_horizontal__l__stroke:before {
  content: "\e9db";
}
.icon_align_vertical__l__stroke:before {
  content: "\e9dc";
}
.icon_distribute_horizontal__l__stroke:before {
  content: "\e9dd";
}
.icon_distribute_vertical__l__stroke:before {
  content: "\e9de";
}
.icon_list__l__stroke:before {
  content: "\e9df";
}
.icon_wide_view__l__filled:before {
  content: "\e9e0";
}
.icon_chair__l__stroke:before {
  content: "\e9e1";
}
.icon_payment_method__l__stroke:before {
  content: "\e9e3";
}
.icon_money__l__stroke:before {
  content: "\e9e4";
}
.icon_collapse__l__stroke:before {
  content: "\e9e7";
}
.icon_expand__l__stroke:before {
  content: "\e9e8";
}
.icon_sun__l__stroke:before {
  content: "\e9e9";
}
.icon_bag__l__stroke:before {
  content: "\e9f1";
}
.icon_bagcrossed__l__stroke:before {
  content: "\e9f3";
}
.icon_globe__l__stroke:before {
  content: "\e9f5";
}
.icon_flag__l__stroke:before {
  content: "\e9f7";
}
.icon_service__l__stroke:before {
  content: "\e9f9";
}
.icon_chart__l__stroke:before {
  content: "\e9fb";
}
.icon_education__l__stroke:before {
  content: "\e9fd";
}
.icon_antenna__l__stroke:before {
  content: "\e9ff";
}
.icon_dna__l__stroke:before {
  content: "\ea01";
}
.icon_basket__l__stroke:before {
  content: "\ea03";
}
.icon_lighting__l__filled:before {
  content: "\ea05";
}
.icon_quote__l__stroke:before {
  content: "\ea07";
}
.icon_qr__l__stroke:before {
  content: "\ea09";
}
.icon_sound_on__l__stroke:before {
  content: "\ea0b";
}
.icon_sound_off__l__stroke:before {
  content: "\ea0c";
}
.icon_key__l__stroke:before {
  content: "\ea0d";
}
.icon_chart__l__stroke1:before {
  content: "\ea0e";
}
.icon_enter__l__stroke:before {
  content: "\ea0f";
}
.icon_add_file__l__stroke:before {
  content: "\ea10";
}
.icon_create_message__l__stroke:before {
  content: "\ea17";
}
.icon_image__l__stroke:before {
  content: "\ea18";
}
.icon_add_link__l__stroke:before {
  content: "\ea19";
}
.icon_prize_cup__l__stroke:before {
  content: "\ea1a";
}
.icon_send__l__stroke:before {
  content: "\ea1b";
}
.icon_todo_list__l__stroke:before {
  content: "\ea1c";
}
.icon_many_files__l__stroke:before {
  content: "\ea1d";
}
.icon_delegate_user__l__stroke:before {
  content: "\ea25";
}
.icon_wifi__l__stroke:before {
  content: "\ea27";
}
.icon_mobile__l__stroke:before {
  content: "\ea28";
}
.icon_shield__l__stroke:before {
  content: "\ea2c";
}
.icon_laptop__l__stroke:before {
  content: "\ea2d";
}
.icon_save__l__stroke:before {
  content: "\ea31";
}
.icon_open_envelope__l__stroke:before {
  content: "\ea33";
}
.icon_sensor__l__stroke:before {
  content: "\ea35";
}
.icon_move_arrows__l__stroke:before {
  content: "\ea36";
}
.icon_select_area__l__stroke:before {
  content: "\ea37";
}
.icon_skip_forward__l__stroke:before {
  content: "\ea38";
}
.icon_skip_backward__l__stroke:before {
  content: "\ea39";
}
.icon_font_color__l__stroke:before {
  content: "\ea3f";
}
.icon_bg_color__l__stroke:before {
  content: "\ea40";
}
.icon_reset_font_style__l__stroke:before {
  content: "\ea41";
}
.icon_book__l__stroke:before {
  content: "\ea45";
}
.icon_book_opened__l__stroke:before {
  content: "\ea46";
}
.icon_pin__l__filled:before {
  content: "\ea49";
}
.icon_diamond__l__stroke:before {
  content: "\ea4b";
}
.icon_crystal__l__stroke:before {
  content: "\ea4c";
}
.icon_crown__l__stroke:before {
  content: "\ea4d";
}
.icon_shine__l__stroke:before {
  content: "\ea4e";
}
.icon_fire__l__stroke:before {
  content: "\ea4f";
}
.icon_service_credit__l__stroke:before {
  content: "\ea55";
}
.icon_comment__l__stroke:before {
  content: "\ea56";
}
.icon_question_mark__l__stroke:before {
  content: "\ea59";
}
.icon_exclamation_mark__l__stroke:before {
  content: "\ea5a";
}
.icon_import__l__stroke:before {
  content: "\ea5d";
}
.icon_export__l__stroke:before {
  content: "\ea5e";
}
.icon_inbox__l__stroke:before {
  content: "\ea61";
}
.icon_outbox__l__stroke:before {
  content: "\ea62";
}
.icon_rocket__l__stroke:before {
  content: "\ea66";
}
.icon_header__l__stroke:before {
  content: "\ea67";
}
.icon_footer__l__stroke:before {
  content: "\ea68";
}
.icon_card__l__stroke:before {
  content: "\ea69";
}
.icon_search:before {
  content: "\e90b";
}
.icon_search__s__stroke:before {
  content: "\e90b";
}
.icon_sorting-Z-A:before {
  content: "\e901";
}
.icon_ascending_sorting__s__stroke:before {
  content: "\e901";
}
.icon_sorting-A-Z:before {
  content: "\e902";
}
.icon_descending_sorting__s__stroke:before {
  content: "\e902";
}
.icon_sort-down:before {
  content: "\e910";
}
.icon_sort_down__s__stroke:before {
  content: "\e910";
}
.icon_sort-up:before {
  content: "\e90f";
}
.icon_sort_up__s__stroke:before {
  content: "\e90f";
}
.icon_upload:before {
  content: "\e903";
}
.icon_upload__s__stroke:before {
  content: "\e903";
}
.icon_download:before {
  content: "\e911";
}
.icon_download__s__stroke:before {
  content: "\e911";
}
.icon_delete:before {
  content: "\e912";
}
.icon_delete__s__stroke:before {
  content: "\e912";
}
.icon_bold:before {
  content: "\e916";
}
.icon_bold__s__stroke:before {
  content: "\e916";
}
.icon_italic:before {
  content: "\e917";
}
.icon_italic__s__stroke:before {
  content: "\e917";
}
.icon_underline:before {
  content: "\e918";
}
.icon_underline__s__stroke:before {
  content: "\e918";
}
.icon_crossed-out:before {
  content: "\e919";
}
.icon_strikethrough__s__stroke:before {
  content: "\e919";
}
.icon_x2:before {
  content: "\e92c";
}
.icon_superscript__s__stroke:before {
  content: "\e92c";
}
.icon_x2-2:before {
  content: "\e92b";
}
.icon_subscript__s__stroke:before {
  content: "\e92b";
}
.icon_list-bulit:before {
  content: "\e921";
}
.icon_unordered_list__s__stroke:before {
  content: "\e921";
}
.icon_number-list:before {
  content: "\e922";
}
.icon_orderlist__s__stroke:before {
  content: "\e922";
}
.icon_truncate:before {
  content: "\e914";
}
.icon_truncate__s__stroke:before {
  content: "\e914";
}
.icon_dots-v:before {
  content: "\e904";
}
.icon_context_menu__s__filled:before {
  content: "\e904";
}
.icon_drag-drop:before {
  content: "\e95d";
}
.icon_draggable_indicator__s__stroke:before {
  content: "\e95d";
}
.icon_align-right:before {
  content: "\e913";
}
.icon_align_right__s__stroke:before {
  content: "\e913";
}
.icon_align-center:before {
  content: "\e908";
}
.icon_align_center__s__stroke:before {
  content: "\e908";
}
.icon_align-left:before {
  content: "\e900";
}
.icon_align_left__s__stroke:before {
  content: "\e900";
}
.icon_hyperlink:before {
  content: "\e923";
}
.icon_link__s__stroke:before {
  content: "\e923";
}
.icon_bookmark:before {
  content: "\e925";
}
.icon_bookmark__s__stroke:before {
  content: "\e925";
}
.icon_bookmark__s__filled:before {
  content: "\e9ef";
}
.icon_info:before {
  content: "\e91a";
}
.icon_info__s__stroke:before {
  content: "\e91a";
}
.icon_close:before {
  content: "\e92d";
}
.icon_close__s__stroke:before {
  content: "\e92d";
}
.icon_plus:before {
  content: "\e947";
}
.icon_plus__s__stroke:before {
  content: "\e947";
}
.icon_minus:before {
  content: "\e946";
}
.icon_minus__s__stroke:before {
  content: "\e946";
}
.icon_add-dot:before {
  content: "\e963";
}
.icon_include__s__stroke:before {
  content: "\e963";
}
.icon_add-line:before {
  content: "\ea65";
}
.icon_add_line__s__stroke:before {
  content: "\ea65";
}
.icon_add-folder:before {
  content: "\e95c";
}
.icon_add_folder__s__stroke:before {
  content: "\e95c";
}
.icon_h1:before {
  content: "\e92f";
}
.icon_heading_1__s__stroke:before {
  content: "\e92f";
}
.icon_h2:before {
  content: "\e930";
}
.icon_heading_2__s__stroke:before {
  content: "\e930";
}
.icon_h3:before {
  content: "\e931";
}
.icon_heading_3__s__stroke:before {
  content: "\e931";
}
.icon_user:before {
  content: "\e938";
}
.icon_user__s__stroke:before {
  content: "\e938";
}
.icon_users:before {
  content: "\e905";
}
.icon_users__s__stroke:before {
  content: "\e905";
}
.icon_addfriend:before {
  content: "\e927";
}
.icon_add_user__s__stroke:before {
  content: "\e927";
}
.icon_achievement:before {
  content: "\e969";
}
.icon_achievement_medal__s__stroke:before {
  content: "\e969";
}
.icon_calendar:before {
  content: "\e90c";
}
.icon_calendar__s__stroke:before {
  content: "\e90c";
}
.icon_add-space:before {
  content: "\e96a";
}
.icon_resize__s__stroke:before {
  content: "\e96a";
}
.icon_arrow-down:before {
  content: "\e932";
}
.icon_chevron_down__s__stroke:before {
  content: "\e932";
}
.icon_arrow-up:before {
  content: "\e935";
}
.icon_chevron_up__s__stroke:before {
  content: "\e935";
}
.icon_arrow-left:before {
  content: "\e933";
}
.icon_chevron_left__s__stroke:before {
  content: "\e933";
}
.icon_arrow-right:before {
  content: "\e934";
}
.icon_chevron_right__s__stroke:before {
  content: "\e934";
}
.icon_check:before {
  content: "\e945";
}
.icon_checkmark__s__stroke:before {
  content: "\e945";
}
.icon_select-all:before {
  content: "\e94b";
}
.icon_select_all__s__stroke:before {
  content: "\e94b";
}
.icon_adjustment:before {
  content: "\e994";
}
.icon_adjustments__s__stroke:before {
  content: "\e994";
}
.icon_archive:before {
  content: "\e915";
}
.icon_archive__s__stroke:before {
  content: "\e915";
}
.icon_edit:before {
  content: "\e90a";
}
.icon_edit__s__filled:before {
  content: "\e90a";
}
.icon_copy:before {
  content: "\e91b";
}
.icon_copy__s__stroke:before {
  content: "\e91b";
}
.icon_chat:before {
  content: "\e91c";
}
.icon_chat__s__stroke:before {
  content: "\e91c";
}
.icon_file:before {
  content: "\e937";
}
.icon_file__s__stroke:before {
  content: "\e937";
}
.icon_folder:before {
  content: "\e909";
}
.icon_folder__s__stroke:before {
  content: "\e909";
}
.icon_email:before {
  content: "\e954";
}
.icon_email__s__stroke:before {
  content: "\e954";
}
.icon_crop:before {
  content: "\e91d";
}
.icon_crop__s__stroke:before {
  content: "\e91d";
}
.icon_setting:before {
  content: "\e91e";
}
.icon_setting__s__stroke:before {
  content: "\e91e";
}
.icon_exit:before {
  content: "\e960";
}
.icon_exit__s__stroke:before {
  content: "\e960";
}
.icon_filter:before {
  content: "\e920";
}
.icon_filter__s__stroke:before {
  content: "\e920";
}
.icon_home:before {
  content: "\e953";
}
.icon_home__s__stroke:before {
  content: "\e953";
}
.icon_eyeopen:before {
  content: "\e92e";
}
.icon_show__s__stroke:before {
  content: "\e92e";
}
.icon_eyeclose:before {
  content: "\e936";
}
.icon_hide__s__stroke:before {
  content: "\e936";
}
.icon_clip:before {
  content: "\e944";
}
.icon_attachment__s__stroke:before {
  content: "\e944";
}
.icon_timer:before {
  content: "\e92a";
}
.icon_clock__s__stroke:before {
  content: "\e92a";
}
.icon_switch:before {
  content: "\e924";
}
.icon_arrange_horizontally__s__stroke:before {
  content: "\e924";
}
.icon_sort:before {
  content: "\e91f";
}
.icon_arrange_vertically__s__stroke:before {
  content: "\e91f";
}
.icon_arrow-long-down:before {
  content: "\e998";
}
.icon_arrow_down__s__stroke:before {
  content: "\e998";
}
.icon_arrow-long-up:before {
  content: "\e997";
}
.icon_arrow_up__s__stroke:before {
  content: "\e997";
}
.icon_arrow_v3:before {
  content: "\e961";
}
.icon_arrow_left__s__stroke:before {
  content: "\e961";
}
.icon_arrow_v2:before {
  content: "\e928";
}
.icon_arrow_right__s__stroke:before {
  content: "\e928";
}
.icon_arrow-round-right:before {
  content: "\e996";
}
.icon_arrow_rotate_right__s__stroke:before {
  content: "\e996";
}
.icon_arrow-back:before {
  content: "\e968";
}
.icon_arrow_back__s__stroke:before {
  content: "\e968";
}
.icon_arrow_redo__s__stroke:before {
  content: "\ea2e";
}
.icon_arrow-turn-right:before {
  content: "\e995";
}
.icon_arrow_turn_right__s__stroke:before {
  content: "\e995";
}
.icon_hourglass:before {
  content: "\e94";
}
.icon_hourglass__s__stroke:before {
  content: "\e94";
}
.icon_canceled-hourglass:before {
  content: "\e999";
}
.icon_canceled_hourglass__s__stroke:before {
  content: "\e999";
}
.icon_print:before {
  content: "\e94a";
}
.icon_print__s__stroke:before {
  content: "\e94a";
}
.icon_exclemation-mark:before {
  content: "\e94d";
}
.icon_alert__s__stroke:before {
  content: "\e94d";
}
.icon_dot-size:before {
  content: "\e962";
}
.icon_target__s__stroke:before {
  content: "\e962";
}
.icon_removefrom:before {
  content: "\e929";
}
.icon_exclude__s__stroke:before {
  content: "\e929";
}
.icon_help:before {
  content: "\e95a";
}
.icon_help__s__stroke:before {
  content: "\e95a";
}
.icon_cancel:before {
  content: "\e991";
}
.icon_cancel__s__stroke:before {
  content: "\e991";
}
.icon_lightbulb:before {
  content: "\e90d";
}
.icon_lightbulb__s__stroke:before {
  content: "\e90d";
}
.icon_location:before {
  content: "\e959";
}
.icon_location__s__stroke:before {
  content: "\e959";
}
.icon_bell:before {
  content: "\e95b";
}
.icon_notification__s__stroke:before {
  content: "\e95b";
}
.icon_heart:before {
  content: "\e956";
}
.icon_heart__s__stroke:before {
  content: "\e956";
}
.icon_heart__s__filled:before {
  content: "\e9f0";
}
.icon_accrual:before {
  content: "\e993";
}
.icon_accrual__s__stroke:before {
  content: "\e993";
}
.icon_credit-memo:before {
  content: "\e992";
}
.icon_credit_memo__s__stroke:before {
  content: "\e992";
}
.icon_discount:before {
  content: "\e990";
}
.icon_percentage__s__stroke:before {
  content: "\e990";
}
.icon_grid__s__filled:before {
  content: "\e907";
}
.icon_pin:before {
  content: "\e952";
}
.icon_pin__s__stroke:before {
  content: "\e952";
}
.icon_lock:before {
  content: "\e95e";
}
.icon_locked__s__stroke:before {
  content: "\e95e";
}
.icon_unlock:before {
  content: "\e95f";
}
.icon_unlocked__s__stroke:before {
  content: "\e95f";
}
.icon_camera:before {
  content: "\e955";
}
.icon_camera__s__stroke:before {
  content: "\e955";
}
.icon_screen:before {
  content: "\e958";
}
.icon_screen__s__stroke:before {
  content: "\e958";
}
.icon_play-stroke:before {
  content: "\e94f";
}
.icon_play_bordered__s__stroke:before {
  content: "\e94f";
}
.icon_pause-stroke:before {
  content: "\e951";
}
.icon_pause_bordered__s__stroke:before {
  content: "\e951";
}
.icon_play:before {
  content: "\e94e";
}
.icon_play__s__stroke:before {
  content: "\e94e";
}
.icon_pause:before {
  content: "\e950";
}
.icon_pause__s__stroke:before {
  content: "\e950";
}
.icon_share:before {
  content: "\e957";
}
.icon_share__s__stroke:before {
  content: "\e957";
}
.icon_external-link:before {
  content: "\e90e";
}
.icon_external_link__s__stroke:before {
  content: "\e90e";
}
.icon_star-border:before {
  content: "\e948";
}
.icon_star__s__stroke:before {
  content: "\e948";
}
.icon_star-filled:before {
  content: "\e949";
}
.icon_star__s__filled:before {
  content: "\e949";
}
.icon_scissors:before {
  content: "\e926";
}
.icon_cut__s__stroke:before {
  content: "\e926";
}
.icon_align-horizontal:before {
  content: "\e964";
}
.icon_align_horizontal__l__stroke1:before {
  content: "\e964";
}
.icon_align-vertical:before {
  content: "\e965";
}
.icon_align_vertical__s__stroke:before {
  content: "\e965";
}
.icon_distribute-horizontal:before {
  content: "\e967";
}
.icon_distribute_horizontal__s__stroke:before {
  content: "\e967";
}
.icon_distribute-vertical:before {
  content: "\e966";
}
.icon_distribute_vertical__s__stroke:before {
  content: "\e966";
}
.icon_list:before {
  content: "\e906";
}
.icon_list__s__stroke:before {
  content: "\e906";
}
.icon_wide-view:before {
  content: "\e94c";
}
.icon_wide_view__s__filled:before {
  content: "\e94c";
}
.icon_chair__s__stroke:before {
  content: "\e9e2";
}
.icon_payment_method__s__stroke:before {
  content: "\e9e5";
}
.icon_money__s__filled:before {
  content: "\e9e6";
}
.icon_collapse__s__stroke:before {
  content: "\e9ea";
}
.icon_expand__s__stroke:before {
  content: "\e9eb";
}
.icon_sun__s__stroke:before {
  content: "\e9ec";
}
.icon_bag__s__stroke:before {
  content: "\e9f2";
}
.icon_bagcrossed__s__stroke:before {
  content: "\e9f4";
}
.icon_globe__s__stroke:before {
  content: "\e9f6";
}
.icon_flag__s__stroke:before {
  content: "\e9f8";
}
.icon_service__s__stroke:before {
  content: "\e9fa";
}
.icon_chart__s__stroke:before {
  content: "\e9fc";
}
.icon_education__s__stroke:before {
  content: "\e9fe";
}
.icon_antenna__s__stroke:before {
  content: "\ea00";
}
.icon_dna__s__stroke:before {
  content: "\ea02";
}
.icon_basket__s__stroke:before {
  content: "\ea04";
}
.icon_lighting__s__filled:before {
  content: "\ea06";
}
.icon_quote__s__stroke:before {
  content: "\ea08";
}
.icon_qr__s__stroke:before {
  content: "\ea0a";
}
.icon_sound_on__s__stroke:before {
  content: "\ea11";
}
.icon_sound_off__s__stroke:before {
  content: "\ea12";
}
.icon_key__s__stroke:before {
  content: "\ea13";
}
.icon_chart__s__stroke1:before {
  content: "\ea14";
}
.icon_enter__s__stroke:before {
  content: "\ea15";
}
.icon_add_file__s__stroke:before {
  content: "\ea16";
}
.icon_create_message__s__stroke:before {
  content: "\ea1e";
}
.icon_image__s__stroke:before {
  content: "\ea1f";
}
.icon_add_link__s__stroke:before {
  content: "\ea20";
}
.icon_prize_cup__s__stroke:before {
  content: "\ea21";
}
.icon_send__s__stroke:before {
  content: "\ea22";
}
.icon_todo_list__s__stroke:before {
  content: "\ea23";
}
.icon_many_files__s__stroke:before {
  content: "\ea24";
}
.icon_delegate_user__s__stroke:before {
  content: "\ea26";
}
.icon_wifi__s__stroke:before {
  content: "\ea29";
}
.icon_mobile__s__stroke:before {
  content: "\ea2a";
}
.icon_shield__s__stroke:before {
  content: "\ea2f";
}
.icon_laptop__s__stroke:before {
  content: "\ea30";
}
.icon_save__s__stroke:before {
  content: "\ea32";
}
.icon_open_envelope__s__stroke:before {
  content: "\ea34";
}
.icon_sensor__s__stroke:before {
  content: "\ea3a";
}
.icon_move_arrows__s__stroke:before {
  content: "\ea3b";
}
.icon_select_area__s__stroke:before {
  content: "\ea3c";
}
.icon_skip_forward__s__stroke:before {
  content: "\ea3d";
}
.icon_skip_backward__s__stroke:before {
  content: "\ea3e";
}
.icon_font_color__s__stroke:before {
  content: "\ea42";
}
.icon_bg_color__s__stroke:before {
  content: "\ea43";
}
.icon_reset_font_style__s__stroke:before {
  content: "\ea44";
}
.icon_book__s__stroke:before {
  content: "\ea47";
}
.icon_book_opened__s__stroke:before {
  content: "\ea48";
}
.icon_pin__s__filled:before {
  content: "\ea4a";
}
.icon_diamond__s__stroke:before {
  content: "\ea50";
}
.icon_crystal__s__stroke:before {
  content: "\ea51";
}
.icon_crown__s__stroke:before {
  content: "\ea52";
}
.icon_shine__s__stroke:before {
  content: "\ea53";
}
.icon_fire__s__stroke:before {
  content: "\ea54";
}
.icon_service_credit__s__stroke:before {
  content: "\ea57";
}
.icon_comment__s__stroke:before {
  content: "\ea58";
}
.icon_question_mark__s__stroke:before {
  content: "\ea5b";
}
.icon_exclamation_mark__s__stroke:before {
  content: "\ea5c";
}
.icon_import__s__stroke:before {
  content: "\ea5f";
}
.icon_export__s__stroke:before {
  content: "\ea60";
}
.icon_inbox__s__stroke:before {
  content: "\ea63";
}
.icon_outbox__s__stroke:before {
  content: "\ea64";
}
.icon_rocket__s__stroke:before {
  content: "\ea6a";
}
.icon_header__s__stroke:before {
  content: "\ea6b";
}
.icon_footer__s__stroke:before {
  content: "\ea6c";
}
.icon_card__s__stroke:before {
  content: "\ea6d";
}
