@import './assets/styles/main';

.mandatory-field{
    color: red;
}

.generate-summary-fields{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.generate-summary-label{
  width: 400px;
}

.collapsibleText
{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
kendo-textarea-suffix {
  justify-content: end !important;
}
