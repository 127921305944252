$contentMaxWidth: 100%;

body {
  font: 14px/1.429 Open Sans, sans-serif;
}

.container {
  width: 100%;
  max-width: $contentMaxWidth;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.icon {
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  background-size: contain;

  &_pdf {
    background-image: url(/assets/images/core/icon-pdf.svg);
  }

  &_ppt {
    background-image: url(/assets/images/core/icon-ppt.svg);
  }

  &_html {
    background-image: url(/assets/images/core/icon-html.svg);
  }

  &_pdfcompletedreview {
    margin-left: 21px !important;
    background-image: url(/assets/images/core/icon-pdf.svg);
  }

  &_pptcompletedreview {
    margin-left: 22px !important;
    background-image: url(/assets/images/core/icon-ppt.svg);
  }
}

.link {
  display: inline-flex;
  align-items: center;
  color:white;
  text-decoration: none;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &__text {
    text-decoration: underline;

    &+.icon {
      margin-left: 12px;
    }
  }

  &_dark {
    color: #000;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #005587;
    outline-offset: 0;
  }
}
.button-notification { font-size: 16px; }
