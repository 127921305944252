.k-button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  font-family: inherit;
  border: none;
  border-radius: 0;
  font-weight: 400;

  &:before,
  &:after {
    display: none !important;
  }

  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    outline: 2px solid #005587;
    outline-offset: 0;
  }

  &-text {
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  &-text {
    font-family: 'Open Sans', sans-serif;
  }

  .icon {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
  }

  &-sm {
    min-width: 26px;
    min-height: 24px;
    padding: 3px 8px 5px;
    font-size: 12px;
    line-height: 16px;

    .icon {
      width: 16px;
      height: 16px;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &-md {
    min-width: 36px;
    min-height: 32px;
    padding: 5px 12px 7px;
  }

  &-lg {
    min-width: 44px;
    min-height: 40px;
    padding: 9px 16px 11px;
  }

  &-flat {
    &:hover {
      background-color: #00000014 !important;
    }

    &:active {
      background-color: #0000001f !important;
    }
  }

  &-flat-dark {
    color: #fff;

    &:hover {
      background-color: #292929 !important;
    }

    &:active {
      background-color: #3d3d3d !important;
    }
  }

  &-solid-dark {
    background: #000;

    &:hover {
      background-color: #292929;
    }

    &:active {
      background-color: #3d3d3d;
    }

    &.active {
      background: #fff;
      color: #000;
    }
  }

  &-solid-primary {
    background: #26890d;

    &:hover {
      background-color: #20730b;
    }

    &:active {
      background-color: #1d680a;
    }
  }

  &-flat-primary {
    color: #26890d;

    &:hover {
      background-color: #00000014;
      color: #1a5d09;
    }

    &:active {
      background-color: #0000001f;
      color: #144707;
    }
  }
  &-outline-secondary {
    background-color: #0000;
    box-shadow: inset 0 0 0 1px #53565a;
    color: #53565a;

    &:hover {
      background-color: #00000014;
      color: #383a3d;
      box-shadow: inset 0 0 0 1px #383a3d;
    }
    &:active {
      background-color: #0000001f;
      color: #2b2d2f;
      box-shadow: inset 0 0 0 1px #2b2d2f;
    }
    &:focus {
      box-shadow: inset 0 0 0 1px #2b2d2f;
    }
  }
}
