.k-dialog {
  font-family: inherit;
  border-radius: 0;
  box-shadow: none;

  &-titlebar {
    padding: 16px 8px 16px 16px;
  }

  &-title {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  &-content {
    padding: 9px 16px;
  }

  &-content+&-actions {
    padding: 0px;
    column-gap: 8px;
  }
}

.k-overlay {
  background: #000;
  opacity: .5;
}
