.k-grid {
  border: none;
  font-family: 'Open Sans', sans-serif;

  &.k-grid_contract {
    .k-table-th.k-grid-header-sticky,
    .k-table-td.k-grid-content-sticky {

      &:nth-child(1) {
        border-right-width: 1px !important;
      }
    }
  }

  &.k-grid_close-out {
    .k-table-th.k-grid-header-sticky,
    .k-table-td.k-grid-content-sticky {

      &:nth-child(2) {
        border-right-width: 0;
      }

      &:nth-child(3) {
        border-right-width: 1px !important;
      }
    }
  }

  .k-header {
    font-size: 12px;
    font-weight: 400;
    color: #53565A;
    padding: 0;
  }

  .k-cell-inner {
    margin: 0;
    padding: 0;


    &>.k-link {
      padding: 20px 16px 19px;
      user-select: none;
    }
  }

  .k-column-title {
    line-height: 18px;
    margin-right: auto !important;
  }

  .k-grid-header {
    padding-right: 0 !important;
    border-color: #d0d0ce;

    .k-table-th.k-grid-header-sticky {
      border-color: #d0d0ce;

      &:nth-child(1) {
        border-right-width: 0;
      }
    }

    .k-table-th:last-child {
      justify-content: flex-end;
      border-right: none;
    }
  }

  .k-grid-content {
    overflow-y: hidden;

    .k-table-row {
      &:hover {
        background: #ebebeb !important;

        .k-grid-content-sticky {
          background: #ebebeb;
        }
      }
    }
  }

  .k-table-td {
    padding: 11px 16px;
    border-color: #d0d0ce;
    color: #000;
    border-bottom: 1px solid #d0d0ce !important;

    &:last-child {
      border-right: none;
    }

    &.k-grid-content-sticky {
      border-color: #d0d0ce;
      white-space: normal;

      &:nth-child(1) {
        border-right-width: 0;
      }
    }
  }

  .k-grid-pager {
    justify-content: flex-end;
    background: transparent;
    border-color: #d0d0ce;
    padding: 19px 0 0;
  }

  .k-pager-numbers {
    column-gap: 4px;
  }

  .k-pager-nav {
    min-width: auto;
    padding: 7px 13px;

    &.k-button-flat-primary {
      color: #000;
    }

    &.k-icon-button {
      padding: 5px 6px;
    }

    &:focus-visible {
      outline-offset: -2px;
    }

    .k-button-text {
      min-width: auto;
    }

    &:hover {
      background-color: #00000014;
    }

    &:active {
      background-color: #0000001f;
    }

    &.k-selected {
      background-color: #000;
      color: #fff;
      pointer-events: none;
    }
  }

  .k-sort-icon,
  .k-svg-i-sort-asc-small,
  .k-svg-i-sort-desc-small,
  .k-svg-i-filter {
    font-family: "icon";
    font-size: 14px;
    color: #53565A;
  }

  .k-svg-i-sort-asc-small,
  .k-svg-i-sort-desc-small,
  .k-svg-i-filter {
    svg {
      display: none;
    }
  }

  .k-svg-i-sort-desc-small:before {
    content: "\e910";
  }

  .k-svg-i-sort-asc-small:before {
    content: "\e90f";
  }

  .k-svg-i-filter:before {
    content: "\e920";
  }

  .k-sort-icon {
    display: flex;
    align-items: center;
    line-height: 16px;

    &:before {
      content: "\e910";
      opacity: .7;
    }
  }

  .k-link:hover .k-sort-icon:before {
    opacity: 1;
  }

  .k-sorted .k-sort-icon:before {
    display: none;
  }
}

.k-grid-filter-popup {
  border-radius: 0;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #d0d0ce;
}

.k-filter-menu-container {
  padding: 0;

  .k-actions {
    padding: 2px 12px 12px;
    flex-direction: row-reverse;
  }

  .k-actions-stretched>* {
    flex: initial;
  }

  .k-button-solid-base {
    color: #53565a;
    border: 1px solid #53565a;

    &:hover {
      background-color: #00000014;
      color: #383a3d;
      border-color: #383a3d;
    }

    &:active {
      background-color: #0000001f;
      color: #2b2d2f;
      border-color: #2b2d2f;
    }
  }

  .k-button-solid-primary {
    background: #000;
    color: #fff;

    &:hover {
      background-color: #292929;
    }

    &:active {
      background-color: #3d3d3d;
    }

    &:disabled {
      pointer-events: none;
      cursor: default;
      opacity: .4;
    }
  }
}
