.k-tabstrip {
  font-family: Open Sans, sans-serif;

  &-items-wrapper {
    padding: 0 24px;

    .k-item {
      &:after {
        content: none;
      }

      &:hover {
        color: #000;
        background-color: #00000014;
      }

      &:active {
        box-shadow: none;
        font-weight: normal;
      }

      &:focus {
        background-color: #00000014;
        box-shadow: none;
      }

      &.k-active {
        background-color: #26890D;
        color: #fff;
        font-weight: normal;
        border: none;

        .k-link {
          cursor: default;
        }

        &:hover {
          color: #fff;
          background-color: #26890D;
          cursor: default;
        }
      }
    }
  }

  &-content {
    padding: 23px 24px;
  }

  &-items {
    box-shadow: 0px -1px 0px 0px #D0D0CE inset;

    .k-link {
      padding: 5px 12px 7px;
    }
  }
}
