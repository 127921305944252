.k-checkbox {
  border-radius: 0;
  width: 16px;
  height: 16px;
  border-color: #000;

  &:hover:not(:checked) {
    background: #d6d6d6;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #005587;
    outline-offset: -2px;
  }

  &:checked {
    background-color: #000;
    border-color: #000;
    background-size: 82%;
    background-position: center;
  }

  &:checked:hover {
    background-color: #525252;
    border-color: #525252;
  }
}
